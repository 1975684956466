<template>
  <div>
    <vx-card class="mb-base directors-card" v-if="type === 'lcrooms'">
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-center">
            <router-link :to="{ name: 'director-learning-centre-list' }"
            >Manage</router-link
            >
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7" />
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <router-link
              :to="{
                name: 'director-learning-centre-view',
                params: { id: this.centerId }
              }"
            >{{ this.centerName }}
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7" />
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>Rooms</a>
          </li>
        </ul>
      </div>

      <div class="m-5">
        <div
          slot="header"
          class="flex flex-wrap flex-grow justify-between mb-8"
        >
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            class="mb-5"
          >
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-xs="12"
              vs-sm="6"
              vs-lg="6"
            >
              <vs-input
                class="w-full search-input"
                v-model="searchQuery"
                placeholder="Search..."
              />
            </vs-col>
          </vs-row>
        </div>
        <vs-table
          max-items="5"
          pagination
          :data="roomData"
          :noDataText="noDataText"
        >
          <template slot="thead">
            <vs-th>Logo</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Location</vs-th>
            <vs-th>Age Group</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.logo">
                <vs-avatar :src="tr.logo"></vs-avatar>
              </vs-td>
              <vs-td :data="tr.name">{{ tr.name }}</vs-td>
              <vs-td :data="tr.location">{{ tr.location }}</vs-td>
              <vs-td :data="tr.ageGroup[0].ageGroup"
              >{{ tr.ageGroup && tr.ageGroup.length> 0 ? tr.ageGroup[0].ageGroup: '-' }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <vx-card class="mb-base directors-card" v-if="type === 'teachers'">
      <vs-table :data="teacherData" :noDataText="noDataText">
        <div slot="header" class="w-full">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex items-center">
                <router-link :to="{ name: 'director-learning-centre-list' }"
                >Manage</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex items-center">
                <router-link
                  :to="{
                    name: 'director-learning-centre-view',
                    params: { id: this.centerId }
                  }"
                >{{ this.centerName }}
                </router-link>
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>

              <li class="inline-flex items-center">
                <a href="javascript:void(0)" class>Teachers</a>
              </li>
            </ul>
          </div>
          <div class="flex flex-wrap items-center my-8">
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
            >
              <vs-col>
                <vs-input
                  class="w-full search-input"
                  icon-pack="feather"
                  icon="icon-search"
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </vs-col>
            </vs-row>

            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
            >
              <vs-col>
                <div class="select-wrapper mt-0">
                  <div class="vs-component is-label-placeholder">
                    <v-select
                      :options="sortOptions"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="sortByFilter"
                    >
                      <template #header>
                        <label for class="label">Sort By</label>
                      </template>
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <vs-icon icon="arrow_drop_down"></vs-icon>
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="left"
                vs-xs="12"
                vs-sm="12"
                vs-lg="12"
              >
                <div class="centerx mx-1">
                  <vs-button
                    color="primary"
                    @click="csvUpload.config.popupToUploadCsv = true"
                    type="filled"
                  >Mass Upload</vs-button
                  >
                  <vs-popup
                    title="Mass Upload"
                    :active.sync="csvUpload.config.popupToUploadCsv"
                  >
                    <div class="centerx">
                      <p>
                        “This Mass Upload enables you to upload several Teachers
                        at once.”
                      </p>
                      <p class="mt-2">
                        “The “Upload Template” option will provide a file in which
                        to populate the Teachers details which can be uploaded at
                        the same time. Remember to save as a csv. File.”<br><br>
                        <strong>"Please enter the correct room name and note that the role section must be in the following format."<br>
                          Teacher: teacher<br>
                          Lead teacher: leadTeacher<br><br>
                        </strong>
                      </p>
                      <p class="mt-2">
                        “When the file is ready use the Mass Upload option below
                        to select the file from your saved location which will
                        be followed by an “Import” option.”
                      </p>
                      <div>
                        <vs-input
                          hidden
                          v-model="csvUpload.data.learningCenterId"
                          class="mt-5 w-full"
                          name="CenterId"
                        />
                      </div>

                      <div>
                        <vs-input
                          hidden
                          v-model="csvUpload.data.directorId"
                          class="mt-5 w-full"
                          name="directorId"
                        />
                      </div>

                      <div>
                        <div class="upload-img">
                          <input
                            type="file"
                            class="hidden"
                            ref="uploadImgInput"
                            @change="updateCurrImg"
                            accept="text/csv"
                          />
                          <vs-button
                            type="border"
                            class="btn-blue-border"
                            @click="$refs.uploadImgInput.click()"
                          >Mass Upload
                          </vs-button>
                        </div>
                      </div>

                      <div v-if="showMassUploadButton" class="mt-5">
                        <vs-button @click="submitCsv">Import</vs-button>
                      </div>
                    </div>
                  </vs-popup>
                </div>
                <div class="mx-1">
                  <vs-button color="primary" :href="templateLink" type="filled">
                    Upload Template
                  </vs-button>
                </div>
                <vs-button
                  class="mb-md-0 mb-2"
                  align="right"
                  @click="addNewTeacher"
                >Add Teacher</vs-button
                >
              </vs-col>
            </vs-row>
            <!--
            <div class="w-full md:w-1/3 md:text-right">

            </div> -->
          </div>
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Room</vs-th>
          <vs-th>Role</vs-th>
          <vs-th>Username</vs-th>
          <vs-th>Reset Password</vs-th>
          <vs-th>Account Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.fullName">{{ tr.fullName | capitalize }}</vs-td>
            <vs-td :data="tr.room">{{ tr.room | capitalize }}</vs-td>
            <vs-td :data="tr.role"
            >{{
                tr.role === "teacher"
                  ? tr.role
                  : tr.role.replace("T", " T") | capitalize
              }}
            </vs-td>
            <vs-td :data="tr.email">{{ tr.email }}</vs-td>
            <vs-td :data="tr._id">
              <a href="#" @click="resetPassword(tr._id)">Reset Password</a>
            </vs-td>
            <vs-td :data="tr.accountStatus">
              <vs-button
                class="mr-4 mt-1"
                :color="tr.accountStatus == 'Active' ? 'success' : 'danger'"
              >{{ tr.accountStatus }}
              </vs-button>
            </vs-td>
            <vs-td>
              <router-link
                :to="{ name: 'director-teachers-view', params: { id: tr._id } }"
                class="nav-link d-flex align-items-center active"
              >
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </router-link>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vx-card class="mb-base directors-card" v-if="type === 'children'">
      <!-- <h2 class="m-5">Children List</h2> -->

      <div class="my-5">
        <vs-table
          max-items="50"
          pagination
          :data="childrenData"
          :noDataText="noDataText"
          @sort="handleSort"
        >
          <div slot="header" class="w-full">
            <div class="vx-breadcrumb md:block hidden">
              <ul class="flex flex-wrap items-center">
                <li class="inline-flex items-center">
                  <router-link :to="{ name: 'director-learning-centre-list' }"
                  >Manage</router-link
                  >
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>
                <li class="inline-flex items-center">
                  <router-link
                    :to="{
                      name: 'director-learning-centre-view',
                      params: { id: this.centerId }
                    }"
                  >{{ this.centerName }}
                  </router-link>
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>

                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Children</a>
                </li>
              </ul>
            </div>
            <div class="flex flex-wrap items-center my-8">
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
              >
                <vs-col>
                  <div class="select-wrapper mt-0">
                    <div class="vs-component is-label-placeholder">
                      <v-select
                        :options="sortOptions"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="sortByFilter"
                      >
                        <template #header>
                          <label for class="label">Sort By</label>
                        </template>
                        <template #open-indicator="{ attributes }">
                          <span v-bind="attributes">
                            <vs-icon icon="arrow_drop_down"></vs-icon>
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5"
              >
                <vs-col>
                  <vs-input
                    class="w-full search-input"
                    icon-pack="feather"
                    icon="icon-search"
                    v-model="searchQuery"
                    placeholder="Search..."
                  />
                </vs-col>
              </vs-row>
              <vs-row class="mb-5 md:mb-0 w-full md:w-1/3 md:pr-5">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="left"
                  vs-xs="12"
                  vs-sm="12"
                  vs-lg="12"
                >
                  <div class="centerx mx-1">
                    <vs-button
                      color="primary"
                      @click="csvChildrenUpload.config.popupToUploadCsv = true"
                      type="filled"
                    >
                      Mass Upload
                    </vs-button>
                    <vs-popup
                      title="Mass Upload"
                      :active.sync="csvChildrenUpload.config.popupToUploadCsv"
                    >
                      <div class="centerx">
                        <p>
                          This Mass Upload enables you to upload several
                          Children at once.
                        </p>
                        <p class="mt-2">
                          The “Upload Template” option will provide a file in
                          which to populate the Child & Parent / Guardian
                          details which can be uploaded at the same time.
                          Remember to save as a CSV. File.
                        </p>
                        <p class="mt-2">
                          When the file is ready use the Mass Upload option
                          below to select the file from your saved location
                          which will be followed by an “Import” option.
                        </p>
                        <div>
                          <vs-input
                            hidden
                            v-model="csvChildrenUpload.data.learningCenterId"
                            class="mt-5 w-full"
                            name="CenterId"
                          />
                        </div>

                        <div>
                          <vs-input
                            hidden
                            v-model="csvChildrenUpload.data.directorId"
                            class="mt-5 w-full"
                            name="directorId"
                          />
                        </div>

                        <div>
                          <div class="upload-img">
                            <input
                              type="file"
                              class="hidden"
                              ref="uploadImgInput1"
                              @change="updateCurrImg2"
                              accept="text/csv"
                            />
                            <vs-button
                              type="border"
                              class="btn-blue-border"
                              @click="$refs.uploadImgInput1.click()"
                            >
                              Mass Upload
                            </vs-button>
                          </div>
                        </div>

                        <div v-if="showChildMassUploadButton" class="mt-3">
                          <vs-button @click="submitChildrenCsv"
                          >Import</vs-button
                          >
                        </div>
                      </div>
                    </vs-popup>
                  </div>
                  <div class="mx-1">
                    <vs-button
                      color="primary"
                      :href="childCSVLink"
                      type="filled"
                    >
                      Upload Template
                    </vs-button>
                  </div>
                  <div class="w-full md:w-1/3 md:text-right">
                    <vs-button
                      class="mb-md-0 mb-2"
                      align="right"
                      @click="addNewChild"
                    >Add Child</vs-button
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <template slot="thead">
            <vs-th>Child's Name</vs-th>
            <vs-th>Child ID</vs-th>
            <vs-th>Room</vs-th>
            <vs-th>Age Group</vs-th>
            <vs-th>Primary Parents/Guardian</vs-th>
            <vs-th>Account Status</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.fullName">{{ tr.fullName | capitalize }}</vs-td>
              <vs-td :data="tr.childId"
              >{{ "undefined" != tr.childId ? tr.childId : "" }}
              </vs-td>
              <vs-td>{{ tr.room.length > 0 ? tr.room[0].name : "" }}</vs-td>
              <vs-td :data="tr.ageGroupId.ageGroup"
              >{{
                  tr.ageGroup.length > 0
                    ? tr.ageGroup[0].ageGroup + " Years"
                    : "-"
                }}
              </vs-td>

              <vs-td :data="tr.gender"
              >{{ "undefined" != tr.mainParent ? tr.mainParent : "" }}
              </vs-td>
              <vs-td :data="tr.accountStatus">
                <vs-button
                  class="mr-4 mt-1"
                  :color="tr.accountStatus == 'Active' ? 'success' : 'danger'"
                >{{ tr.accountStatus }}
                </vs-button>
              </vs-td>
              <vs-td>
                <router-link
                  :to="{
                    name: 'director-children-view',
                    params: { id: tr._id }
                  }"
                  class="nav-link d-flex align-items-center active"
                >
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                  />
                </router-link>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <scroll-loader
      v-if="type === 'teachers'"
      :loader-method="test"
      :loader-enable="loadMore"
    >
      <div></div>
    </scroll-loader>
  </div>
</template>

<script>
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import _ from "lodash";

Vue.use(ScrollLoader);

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      loadMore: true,
      roomData: [],
      teacherData: [],
      childrenData: [],
      type: this.$route.params.type,
      centerId: this.$route.params.id,
      searchQuery: "",
      awaitingSearch: false,
      dataTableParams: {
        search: "",
        id: "",
        limit: this.$route.params.type == "teachers" ? 1000 : 1000,
        sort: "room",
        dir: "asc",
        page: this.$route.params.type == "teachers" ? 0 : 1
      },
      sortByFilter: {
        label: "Room",
        value:
          this.$route.params.type == "teachers" ? "children.roomId" : "roomId"
      },
      sortOptions: [
        {
          label: "Room",
          value: this.$route.params.type == "teachers" ? "room" : "roomId"
        },
        {
          label: this.$route.params.type == "teachers" ? "Role" : "Age Group",
          value: this.$route.params.type == "teachers" ? "role" : "ageGroupId"
        }
      ],
      centerName: null,
      noDataText: "Currently no record available",
      csvUpload: {
        config: {
          uploadCsvUrl:
            "http://localhost:3000/api/v1/centerAdmin/teacher/import",
          popupToUploadCsv: false,
          headers: {
            /*"Content-Type": "application/json",
              "Access-Control-Allow-Headers": "Content-Type",*/
          }
        },
        data: {
          learningCenterId: null,
          directorId: null
        }
      },
      csvChildrenUpload: {
        config: {
          uploadCsvUrl:
            "http://localhost:3000/api/v1/centerAdmin/children/import",
          popupToUploadCsv: false,
          headers: {
            /*"Content-Type": "application/json",
              "Access-Control-Allow-Headers": "Content-Type",*/
          }
        },
        data: {
          learningCenterId: null,
          directorId: null
        }
      },
      templateLink: process.env.VUE_APP_DOMAIN + "/template/teacher.csv",
      image: "",
      showMassUploadButton: false,
      childCSVLink: process.env.VUE_APP_DOMAIN + "/template/children.csv",
      showChildMassUploadButton: false,
      childrenCsv: ""
    };
  },
  methods: {
    ...mapActions("center", [
      "getDirectorCenterById",
      "getDirectorCenterTeacherList",
      "getDirectorCenterRoomsList",
      "getDirectorCenterChildrenList",
      "resetUserPassword"
    ]),
    ...mapActions("centerAdmin", ["importTeacherCsv", "importChildrenCsv"]),

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          // this.logo = e.target.result
        };
        reader.readAsDataURL(input.target.files[0]);
      }
      this.showMassUploadButton = true;
    },
    updateCurrImg2(input) {
      if (input.target.files && input.target.files[0]) {
        this.childrenCsv = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          // this.logo = e.target.result
        };
        reader.readAsDataURL(input.target.files[0]);
      }
      this.showChildMassUploadButton = true;
    },
    refresh(done) {
      this.fetchTeacherList(this.centerId);
    },
    test() {
      this.fetchTeacherList(this.centerId);
    },
    test1() {},
    resetPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want reset password for this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(result => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },
    acceptPasswordResetAlert(id) {
      this.resetUserPassword(id)
        .then(() => {
          this.showChangePasswordSuccess();
        })
        .catch(err => {
          console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
    },
    fetchRoomList(id) {
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.id = id;
      this.getDirectorCenterRoomsList(this.dataTableParams).then(res => {
        this.roomData = res.data.data;
        if (this.roomData.length == 0 && "" != this.searchQuery) {
          this.noDataText =
            "Cannot find room with search text: '" + this.searchQuery + "'";
        }
        this.$vs.loading.close();
      });
    },
    fetchTeacherList(id = this.centerId, type = null) {
      this.$vs.loading();
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.id = id;
      this.dataTableParams.page++;
      if (type) {
        this.dataTableParams.page = 1;
      }
      this.getDirectorCenterTeacherList(this.dataTableParams).then(
        async res => {
          const a = await res.data.data.data;
          this.$vs.loading.close();

          if (type) {
            this.teacherData = res.data.data.data;
          } else {
            a.forEach(item => {
              this.teacherData.push(item);
            });
          }

          res.data.data.data.length < this.pageSize && (this.loadMore = false);

          if (this.teacherData.length == 0 && "" != this.searchQuery) {
            this.noDataText =
            "Currently no teacher available";
          }
          this.$vs.loading.close();
        }
      );
      this.$vs.loading.close();
    },
    fetchChildrenList(id) {
      let self = this;
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.id = id;
      this.dataTableParams.sort = this.sortByFilter.value;

      this.getDirectorCenterChildrenList(this.dataTableParams).then(
        async res => {
          self.childrenData = await res.data.data.docs;

          await _.forEach(self.childrenData, (o, k) => {
            let primaryParent = _.find(o.parents, p => {
              return p.isPrimary == true;
            });
            _.forEach(o.parentData, (p, ki) => {
              if (
                "undefined" != typeof primaryParent &&
                p._id == primaryParent.parentId
              ) {
                if (!p.deleted) {
                  self.childrenData[k].mainParent = p.fullName;
                } else {
                  self.childrenData[k].mainParent = "";
                }
              }
            });
          });

          if (self.childrenData.length == 0 && "" != this.searchQuery) {
            self.noDataText =
              "Cannot find children with search text: '" +
              this.searchQuery +
              "'";
          }
          this.$vs.loading.close();
        }
      );
    },
    addNewChild() {
      this.$router.push({
        name: "director-children-add",
        params: { id: this.centerId }
      });
    },
    addNewTeacher() {
      this.$router.push({
        name: "director-teacher-add-learning-center",
        params: { id: this.centerId }
      });
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      let self = this;
      this.centerId = this.$route.params.id;
      this.fetchChildrenList(self.centerId);
    },
    async getCenterDetails(id) {
      let self = this;
      await this.getDirectorCenterById(id)
        .then(async res => {
          let result = await res.data.data;
          this.centerName = result.name;
        })
        .catch(err => {
          console.error(err);
        });
    },
    async submitCsv() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("teacher", this.image);
          data.append("learningCenterId", this.centerId);
          data.append("directorId", this.director._id);

          this.customError = "";
          this.$vs.loading();
          this.importTeacherCsv(data)
            .then(res => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Teacher Csv Import",
                text: `Teacher file has been imported successfully. ${JSON.stringify(
                  res.data.data
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              this.$router.go(this.$router.currentRoute)
            })
            .catch(err => {
              this.$vs.notify({
                title: "Failure",
                text: `Could not import csv file. ${JSON.stringify(
                  err.response.data.message
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
              this.$vs.loading.close();
              this.image= "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
            })
            .finally(() => {
              this.$vs.loading.close();
              this.image= "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
              this.$router.go(this.$router.currentRoute)
            });
        }
      });
    },
    async submitChildrenCsv() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("child", this.childrenCsv);
          data.append("learningCenterId", this.centerId);
          data.append("directorId", this.director._id);

          this.customError = "";
          this.$vs.loading();
          this.importChildrenCsv(data)
            .then(res => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Children Csv Import",
                text: `Children file has been imported successfully. ${JSON.stringify(
                  res.data.data
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              this.image= "";
              this.csvChildrenUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
              this.$router.go(this.$router.currentRoute)
            })
            .catch(err => {
              this.$vs.notify({
                title: "Failure",
                text: `Could not import csv file. ${JSON.stringify(
                  err.response.data.message
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
              this.$vs.loading.close();
              this.image= "";
              this.csvChildrenUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
            })
            .finally(() => {
              this.$vs.loading.close();
              this.image= "";
              this.csvChildrenUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
              this.$router.go(this.$router.currentRoute)
            });
        }
      });
    }
  },
  watch: {
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          if (this.type === "lcrooms") this.$vs.loading();
          this.fetchRoomList(this.centerId);
          if (this.type === "teachers") this.$vs.loading();
          this.fetchTeacherList(this.centerId, "search");
          if (this.type === "children") this.$vs.loading();
          this.fetchChildrenList(this.centerId);

          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    sortByFilter(obj) {
      this.centerId = this.$route.params.id;
      this.type = this.$route.params.type;

      if (this.type === "teachers") {
        this.$vs.loading();
        this.dataTableParams.sort = obj.value;
        this.fetchTeacherList(this.centerId, "sort");
      }

      if (this.type === "children") {
        this.$vs.loading();
        this.fetchChildrenList(this.centerId);
      }
    }
  },
  created() {
    let self = this;
    this.type = this.$route.params.type;
    this.centerId = this.$route.params.id;
    this.getCenterDetails(this.centerId);

    this.$vs.loading();
    setTimeout(() => {
      if (self.type === "lcrooms") {
        this.$vs.loading();
        self.noDataText = "Currently no room available";
      }
      if (self.type === "teachers") {
        self.noDataText = "Currently no teacher available";
      }
      if (self.type === "children") {
        this.$vs.loading();
        self.noDataText = "Currently no Children available";
        self.fetchChildrenList(self.centerId);
      }
    }, 300);
    this.$vs.loading.close();
    this.csvUpload.data.learningCenterId = this.centerId;
    this.csvUpload.data.directorId = this.director._id;
    this.csvChildrenUpload.data.learningCenterId = this.centerId;
    this.csvChildrenUpload.data.directorId = this.director._id;
  },
  computed: {
    director() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
<style lang="css" scoped>
.load p[data-v-db7945a8] {
  display: none !important;
}
</style>
